// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-deep-learning-vision-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/deep-learning-vision.js" /* webpackChunkName: "component---src-pages-deep-learning-vision-js" */),
  "component---src-pages-index-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/labs.js" /* webpackChunkName: "component---src-pages-labs-js" */),
  "component---src-pages-machine-learning-crash-course-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/machine-learning-crash-course.js" /* webpackChunkName: "component---src-pages-machine-learning-crash-course-js" */),
  "component---src-pages-machine-learning-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/machine-learning.js" /* webpackChunkName: "component---src-pages-machine-learning-js" */),
  "component---src-pages-natural-language-processing-js": () => import("/Users/kchan/Documents/1-Projects/ioa-site/src/pages/natural-language-processing.js" /* webpackChunkName: "component---src-pages-natural-language-processing-js" */)
}

